<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row>
      <b-col>
        <b-card>
          <!-- header -->
          <b-row
            align-v="center"
            class="mb-2 pb-1"
            style="border-bottom: 1px solid #ddd"
          >
            <b-col
              class="text-center"
              style="cursor: pointer"
              cols="auto"
              @click="$router.go(-1)"
            >
              <b-col cols="12">
                {{ $t("back") }}
              </b-col>
              <b-col cols="12">
                <feather-icon
                  v-if="$i18n.isRTL"
                  icon="ArrowRightIcon"
                  size="14"
                />
                <feather-icon
                  v-else
                  icon="ArrowLeftIcon"
                  size="14"
                />
              </b-col>
            </b-col>
            <b-col
              class="leftBorder"
            >
              <b-row
                align-h="between"
                align-v="center"
              >
                <b-col cols="auto">
                  <h3>test</h3>
                  <span>this is a test</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                sticky-header="1000px"
                no-border-collapse
                responsive
                striped
                hover
                :fields="fields"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script >
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BTable,
  },
  data() {
    return {
      show: true,

      fields: [
        { key: 'id', label: this.$t('id') },
        { key: 'title', label: this.$t('title') },
        { key: 'context', label: this.$t('context') },
        { key: 'updated_at', label: this.$t('updated_at'), sortable: true },
        { key: 'category_title', label: this.$t('category') },
        { key: 'status_value', label: this.$t('status') },
        { key: 'details', label: this.$t('more_details') },
      ],
    }
  },

  created() {
    setTimeout(() => {
      this.show = false
    }, 3000)
  },

  mounted() {
  },
}

</script>

<style>
.leftBorder {
  border-left: 1px solid #ddd;
}

.rightBorder {
  border-right: 1px solid #ddd;
}
</style>
